'use strict';

/**
 * Populates the mirakl offers section on pdp
 */
function loadMiraklOffers() {
    var miraklOffersJson = $('.js-mirakl-offers-json').data('mirakloffers');
    var url = $('.js-mirakl-offers-json').data('url');

    if (typeof miraklOffersJson !== 'undefined' && miraklOffersJson.length) {
        $.ajax({
            url: url,
            type: 'post',
            data: { miraklOffersJson: JSON.stringify(miraklOffersJson) },
            success: function (data) {
                if (data.offersHtml !== '') {
                    $('.mirakl-offer-list').html(data.offersHtml);
                    $('.mirakl-offer-list').removeClass('d-none');
                } else {
                    $('.mirakl-offer-list').addClass('d-none');
                }
            }
        });
        if (miraklOffersJson.length > 0) {
            $('.get-offer-list').removeClass('d-none');
        } else {
            $('.get-offer-list').addClass('d-none');
        }
    } else {
        $('.mirakl-offer-list').addClass('d-none');
    }
}

/**
 * Functionality for show more button - offer list
 */
function showMoreOffers() {
    $(document).on('click', '.show-more-offers', function () {
        var pagesize = parseInt($(this).data('pagesize'), 10);
        var hiddenOffers = $('.offer-list-tile.d-none');
        var listSize = pagesize > hiddenOffers.length ? hiddenOffers.length : pagesize;

        for (var i = 0; i < listSize; i += 1) {
            $(hiddenOffers[i]).removeClass('d-none');
            $(hiddenOffers[i]).addClass('d-flex');
        }

        hiddenOffers = $('.offer-list-tile.d-none');

        if (!hiddenOffers.length) {
            $('.show-more-offers').addClass('d-none');
        }
    });
}

/**
 * Functionality for show  offer list
 */
function showOffers() {
    $(document).on('click', '.get-offer-list', function () {
        document.getElementById('mirakl-offer-list').scrollIntoView();
        return false;
    });
}

module.exports = {
    loadMiraklOffers: loadMiraklOffers,
    showMoreOffers: showMoreOffers,
    showOffers: showOffers
};
